<template>
    <v-footer id="home-footer" color="dark" app dark min-height="72">
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <div
                        class="d-flex flex-wrap justify-md-start justify-center justify-md-none"
                    >
                        <template v-for="(s, i) in social">
                            <a
                                :key="s.link"
                                class="white--text pa-1 pa-md-0"
                                :href="s.link"
                                v-text="s.name"
                            />

                            <v-responsive
                                v-if="i < social.length - 1"
                                :key="`divider-${s.link}`"
                                class="mx-4 shrink hidden-sm-and-down"
                                max-height="24"
                            >
                                <v-divider vertical />
                            </v-responsive>
                        </template>
                    </div>
                </v-col>

                <v-col class="text-center text-md-right" cols="12" md="6"
                    >Copyright &copy; 2020 Sylvaleo Global, Limited</v-col
                >
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: 'HomeFooter',

    data: () => ({
        social: [
            {
                name: 'Facebook',
                link:
                    'https://www.facebook.com/Sylvaleo-Global-Limited-SGL-102502484928387/',
            },
            {
                name: 'Twitter',
                link: 'https://twitter.com/SylvaleoL?s=08',
            },
            {
                name: 'Instagram',
                link: 'https://instagram.com/sylvaleoglobal',
            },
            {
                name: 'Linkedin',
                link: 'https://linkedin.com/sylvaleoglobal',
            },
        ],
    }),
};
</script>

<style lang="sass">
#home-footer a
    text-decoration: none
</style>
